<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader :title="$t('projectsTransfers.ProjectTransfers')"
                     :description="$t('projectsTransfers.FromHereYouCanControlProjectTransfers')" />
        <indexTable :searchText="$t('projectsTransfers.SearchForAnOperation')"
                    :emptyTableText="$t('projectsTransfers.ThereAreNoOperations')"
                    :emptyTableSubText="$t('projectsTransfers.TryAddingItAfterTheItemsAndTryAgain')"
                    :buttonRole="$user.role.projects_deposits_add"
                    :statusSearch="true"
                    :statusValues="[
                      { title: $t('orders.situation'), value: ''},
                      { title: $t('orders.all'), value: ''},
                      { title: $t('invoices.draft'), value: 0 },
                      { title: $t('invoices.approved'), value: 1 },
                    ]"
                    :cloumns="[
                                  {column: 'code',    title: $t('projectsTransfers.operation') ,type: 'mainLink' , sort: true },
                                  {column: 'from_project_id',    title: $t('projectsTransfers.FromAProject'), type: 'link', to:'fromProject', sort: true, link: true},
                                  {column: 'to_project_id',    title: $t('projectsTransfers.ToAProject'), type: 'link', to:'toProject', sort: true, link: true},
                                  {column: 'date',    title: $t('projectsTransfers.theDateOfTheOperation') ,type: 'text' , sort: true },
                                  {column: 'total',    title: $t('projectsTransfers.Total') ,type: 'text' , sort: true},
                                  {column: 'description',    title:  $t('projectsTransfers.thatAbout') ,type: 'text' , sort: true },
                                  {column: 'status', title: $t('bills.situation') ,type: 'status' , sort: true ,  values: [
                                        {title: $t('invoices.draft'), value: 0, color: 'dark'},
                                        {title: $t('invoices.approved'), value: 1, color: 'success'},
                                      ]
                                  },
                                  {column: 'options', title: $t('projectsTransfers.settings'), type: 'options', options: [
                                      {name: 'show'},
                                      {name: 'printProjectsTransfer',   role: true},
                                      {name: 'edit',   role: $user.admin || $user.role.projects_deposits_edit},
                                      {name: 'file'},
                                      {name: 'printProjectsDeposit',   role: true},
                                      {name: 'delete', role: $user.admin || $user.role.projects_deposits_delete},
                                  ]}
                                ]"
                    :deleteText="{
                                 attention: $t('allerts.Attention'),
                                 areYouReallySureToDeleteTheItem: $t('allerts.areYouReallySureToDeleteTheItem'),
                                 close: $t('allerts.close'),
                                 confirm: $t('allerts.confirm'),
                                 loading: $t('allerts.loading'),
                                }" />
      </div>
    </div>
  </div>
</template>
<script>

  import indexHeader from '../elements/index/indexHeader.vue'
  import indexTable from '../elements/index/indexTable.vue'

  export default {
    data() {
      return {
        path: '/projectsTransfers',
      }
    },
    components: {
      indexHeader,
      indexTable,
    }
  }

</script>
